<vg-player
  [ngClass]="{ 'disabled-controls': !value }"
  [ngStyle]="hasMaxSize ? { 'max-width.px': maxSize.width, 'max-height.px': maxSize.height } : { width: width, height: height }"
  class="e-video-container"
>
  <vg-overlay-play />
  <vg-buffering />

  <vg-scrub-bar [vgSlider]="true" (click)="onVideoElementClickUpdate()" [ngStyle]="{'pointer-events': !allowTimeChange ? 'none' : ''}">
    <vg-scrub-bar-current-time />
    <vg-scrub-bar-buffering-time />
  </vg-scrub-bar>

  <vg-controls>
    <vg-play-pause />
    <vg-playback-button />

    <vg-time-display vgFormat="hh:mm:ss" vgProperty="current" />

    <vg-scrub-bar style="pointer-events: none" />

    <vg-time-display vgFormat="hh:mm:ss" vgProperty="total" />

    <vg-mute />
    <vg-volume />

    <vg-fullscreen />
  </vg-controls>

  <video
    #media
    [vgMedia]="$any(media)"
    (play)="togglePlay(true)"
    (pause)="togglePlay(false)"
    (timeupdate)="onVideoElementUpdate()"
    (ended)="onVideoEnd()"
    (volumechange)="changeVolume()"
    (ratechange)="changeRate()"
    id="singleVideo"
    preload="auto"
  >
    <source [src]="videoFile" type="video/mp4" />
  </video>
</vg-player>
