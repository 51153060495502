import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { VgTimeDisplayComponent } from '@videogular/ngx-videogular/controls';
import { BookmarkNotesService } from '../../services/bookmark-notes.service';

@Component({
  selector: 'app-e-video-player',
  templateUrl: './e-video-player.component.html',
  styleUrl: './e-video-player.component.scss',
})
export class EVideoPlayerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() videoFile!: string;
  @Input() playVideo: boolean | null = null;
  @Input() hasMaxSize: boolean = false;
  @Input() currentTimeStamp!: number;
  @Input() questionTimeStamp!: string | null;
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() isAdmin: boolean = false;
  @Input() maxSize: { width: number; height: number } = { width: 900, height: 420 };
  @Output() videoElementAdded = new EventEmitter<ElementRef<HTMLVideoElement>>();
  @ViewChild(VgTimeDisplayComponent) time!: VgTimeDisplayComponent;
  value!: string;
  @ViewChild('media') videoPlayer!: ElementRef<HTMLVideoElement>;
  @Output() isVideoPlayed: EventEmitter<any> = new EventEmitter<any>();
  @Output() videoTimeUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() videoEnd: EventEmitter<any> = new EventEmitter<any>();
  previousSecond!: number;
  questionTimeStampInSeconds!: number | null;
  changedTimeStamp: any;
  allowTimeChange: boolean = true;

  constructor(private cdr: ChangeDetectorRef, private bookmarkService: BookmarkNotesService) {}

  ngOnInit(): void {
    this.bookmarkService.textareaClicked.subscribe(() => {
      this.bookmarkService.getCurrentTimeStamp(Math.floor(this.videoPlayer.nativeElement.currentTime));
      this.videoPlayer.nativeElement.pause();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['videoFile'] && !changes['videoFile'].firstChange) {
      this.toggleVideoChange();
      this.togglePlay(false);
    }

    if (changes['questionTimeStamp']) {
      if (changes['questionTimeStamp'].currentValue != null) {
        this.questionTimeStampInSeconds = this.timeStringToSeconds(changes['questionTimeStamp'].currentValue);
      } else {
        this.questionTimeStampInSeconds = null;
      }
    }

    if (this.currentTimeStamp == this.changedTimeStamp) {
      setTimeout(() => {
        this.setCurrentTime(this.currentTimeStamp);
      });
    }

    if (changes['currentTimeStamp']) {
      setTimeout(() => {
        this.changedTimeStamp = changes['currentTimeStamp'].currentValue;
        this.setCurrentTime(changes['currentTimeStamp'].currentValue);
      });
    }

    if (changes['playVideo'] && !changes['playVideo'].currentValue && this.videoPlayer) {
      this.currentTimeStamp = this.videoPlayer.nativeElement.currentTime;
      this.videoPlayer.nativeElement.pause();
    }
  }

  changeVolume() {
    sessionStorage.setItem('videoPlayerVolume', this.videoPlayer.nativeElement.volume.toString());
  }

  changeRate() {
    sessionStorage.setItem('videoPlayerSpeed', this.videoPlayer.nativeElement.playbackRate.toString());
  }

  timeStringToSeconds(timeString: string): number {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  ngAfterViewInit() {
    this.toggleVideoChange();
  }

  toggleVideoChange() {
    this.value = this.videoFile;
    this.videoPlayer.nativeElement.load();
    this.cdr.detectChanges();

    var volume = sessionStorage.getItem('videoPlayerVolume');
    if (volume) {
      this.videoPlayer.nativeElement.volume = JSON.parse(volume);
    }

    var speed = sessionStorage.getItem('videoPlayerSpeed');
    if (speed) {
      this.videoPlayer.nativeElement.playbackRate = JSON.parse(speed);
    }

    if (!this.isAdmin) {
      setTimeout(() => {
        this.togglePlay(true);
      });
    }
  }

  togglePlay(isPlaying: boolean): void {
    if (isPlaying) {
      this.playVideo = true;
      this.videoPlayer.nativeElement.play().then(() => {
        this.isVideoPlayed.emit({ timeSpan: this.videoPlayer.nativeElement.currentTime, isPlayed: true, player: this.videoPlayer.nativeElement });
      });
    } else {
      this.videoPlayer.nativeElement.pause();
      this.isVideoPlayed.emit({ timeSpan: this.videoPlayer.nativeElement.currentTime, isPlayed: false, player: this.videoPlayer.nativeElement });
    }
  }

  setCurrentTime(time: number | null) {
    if (time != null && !isNaN(time)) {
      this.videoPlayer.nativeElement.currentTime = time;
    }
  }

  onVideoElementUpdate() {
    const videoElement = this.videoPlayer;
    this.videoElementAdded.emit(videoElement);

    if (videoElement.nativeElement.currentTime != 0) {
      const currentTime = Math.floor(videoElement.nativeElement.currentTime);

      if (currentTime % 5 === 0 && currentTime != this.previousSecond) {
        this.previousSecond = currentTime;
        this.videoTimeUpdate.emit(videoElement);
      }

      if (this.questionTimeStampInSeconds != null) {
        if (currentTime == this.questionTimeStampInSeconds) {
          videoElement.nativeElement.pause();
          this.videoTimeUpdate.emit(videoElement);
        }
      }
    }
  }

  onVideoElementRevert() {
    const videoElement = this.videoPlayer;
    this.videoElementAdded.emit(videoElement);

    if (videoElement.nativeElement.currentTime != 0) {
      const currentTime = Math.floor(videoElement.nativeElement.currentTime);

      this.previousSecond = currentTime;
      this.videoTimeUpdate.emit(videoElement);
    }
  }

  onVideoElementClickUpdate() {
    this.allowTimeChange = false;
    this.togglePlay(true);
    this.onVideoElementRevert();

    setTimeout(() => (this.allowTimeChange = true), 500);
  }

  onVideoEnd() {
    this.videoEnd.emit(this.videoPlayer);
  }
}
