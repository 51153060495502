<div class="editor-container" tabindex="0">
  <quill-editor
    (click)="onEditorFocused()"
    (onBlur)="onBlur()"
    #quillEditor
    [readOnly]="readOnly"
    [bounds]="'self'"
    [maxLength]="maxLength"
    [modules]="editorConfig.modules"
    [ngClass]="getClassObject()"
    (onContentChanged)="onEditorContentChange()"
    [placeholder]="editorConfig.placeholder"
    id="editor-container"
  ></quill-editor>
  <div [ngClass]="showDropdown ? 'character-count' : 'hidden-character-count'">{{ characterCount }}/{{ maxLength }}</div>
</div>

@if (showSaveMessage && showDropdown && hasChanged) {
<div class="info-text">{{ textConstants.NOT_SAVED_CHANGES }}</div>
}
