import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { Delta } from 'quill/core';
import * as TextConstants from '../../constants/text.constants';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-e-dynamic-text-area',
  templateUrl: 'e-dynamic-text-area.component.html',
  styleUrl: 'e-dynamic-text-area.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EDynamicTextAreaComponent),
      multi: true,
    },
  ],
})
export class EDynamicTextAreaComponent implements ControlValueAccessor, OnChanges {
  @Input() editorConfig: any;
  @Input() showDropdown: boolean = false;
  @Input() data = '';
  @Input() placeholderInput = '';
  @Input() maxLength!: number;
  @Input() readOnly!: boolean;
  @Input() showSaveMessage: boolean = false;
  @Input() editorClass: string = 'editor';
  @Input() editorClassHidden: string = 'hidden-toolbar-v2';
  @Output() dataOutput: EventEmitter<any> = new EventEmitter<any>();
  @Output() dropdownVisibility = new EventEmitter<boolean>();
  @Output() editorClosed = new EventEmitter();
  @ViewChild(QuillEditorComponent, { static: true }) quillEditorComponent!: QuillEditorComponent;
  characterCount: number = 0;
  hasChanged: boolean = false;
  textConstants = TextConstants;

  private _value: string = '';
  private onChange: any = () => {};
  private onTouched: any = () => {};
  isUserGlobal!: boolean;
  userRole!: string;

  constructor(private authUserService: AuthService) {}

  ngOnInit() {
    this.editorConfig = {
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ size: [false, 'large'] }],
            ['toggleCase'],
          ],
          handlers: {
            toggleCase: () => {
              this.setTextCaseToggle();
            },
          },
        },
      },
      placeholder: this.placeholderInput,
    };

    if (this.data !== undefined) {
      this.writeValue(this.data);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showDropdown']) {
      this.hasChanged = false;
    }

    if (changes['data'].currentValue === '') {
      // Resets text editor on cancel.
      this._value = '';
      this.setContent('');
    }

    if (changes['data'] && !changes['data'].firstChange && changes['data'].previousValue === undefined) {
      setTimeout(() => {
        this.writeValue(this.data);
      });
    }
  }

  writeValue(value: any): void {
    if (value === undefined) {
      value = ''; // Set default value to empty string if undefined
    }
    if (value !== this._value) {
      this._value = value;
      this.setContent(value); // Set editor content when value changes
    }
  }

  // Handle changes from editor
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Handle touched event
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Update editor content
  onEditorContentChange() {
    const text = this.quillEditorComponent.quillEditor.getText();
    var trimmedText = text.replace(/\n+$/, '');
    this.characterCount = trimmedText.length;
    if (text.length >= this.maxLength) {
      this.quillEditorComponent.quillEditor.deleteText(this.maxLength, trimmedText.length - 1);
    }

    this.dataOutput.emit(this.quillEditorComponent.quillEditor.getSemanticHTML());
    this.hasChanged = true;
    this.onChange(text);
  }

  // Set editor content
  setContent(value: string) {
    this.quillEditorComponent.writeValue(this.data);
  }

  // Store editor content before component is destroyed
  ngOnDestroy(): void {
    this._value = this.quillEditorComponent.quillEditor.root.innerHTML;
  }

  onInput(): void {
    this.data = this.quillEditorComponent.quillEditor.root.innerText;
    this.dataOutput.emit(this.data);
  }

  onBlur() {
    this.editorClosed.emit();
  }

  onEditorFocused() {
    if (!this.showDropdown) {
      this.showDropdown = true;
      this.dropdownVisibility.emit(this.showDropdown);
    }
  }

  onEditorChange(event: any) {
    const data = event.editor.root.innerText;
    this.characterCount = data.length;
    if (data.length > this.maxLength) {
      event.editor.deleteText(this.maxLength, data.length);
      this.onChange(event.editor.getText(0, this.maxLength));
    }

    if (data === '\n') {
      this.characterCount = 0;
    }
  }

  setTextCaseToggle() {
    const range = this.quillEditorComponent.quillEditor.getSelection();
    if (range && range.length > 0) {
      const text = this.quillEditorComponent.quillEditor.getText(range.index, range.length);

      const toggledText = this.convertText(text);

      const delta = new Delta().retain(range.index).delete(range.length).insert(toggledText);

      this.quillEditorComponent.quillEditor.updateContents(delta, 'silent');
      this.onChange(this.quillEditorComponent.quillEditor.root.innerHTML);

      return;
    }

    const text = this.quillEditorComponent.quillEditor.getText();
    if (text.length > 0) {
      const toggledText = this.convertText(text);

      const delta = new Delta().delete(text.length).insert(toggledText);

      this.quillEditorComponent.quillEditor.updateContents(delta, 'silent');
      this.onChange(this.quillEditorComponent.quillEditor.root.innerHTML);
    }
  }

  getClassObject() {
    return {
      [this.editorClass]: this.showDropdown,
      [this.editorClassHidden]: !this.showDropdown,
      invalid: this.showSaveMessage && this.showDropdown && this.hasChanged,
    };
  }

  private convertText(text: string) {
    return Array.from(text)
      .map((char) => {
        return char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase();
      })
      .join('');
  }
}
