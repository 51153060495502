import { EventEmitter, Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BookmarkNotesListingModel } from '../submodules/team-member/models/BookmarkNotesListingModel';
import { BookmarkNoteRequestModel } from '../submodules/team-member/models/BookmarkNoteRequestModel';

@Injectable({
  providedIn: 'root',
})
export class BookmarkNotesService {
  private routeConstants = RouteConstants.BOOKMARK_NOTES_URLS;
  private contentSource = new Subject<{ progressId: string; isVideo: boolean }>();
  private timeStampInSecondsSource = new Subject<number>();
  content: { progressId: string; isVideo: boolean } = { progressId: '', isVideo: false };
  content$ = this.contentSource.asObservable();
  timeStampInSeconds$ = this.timeStampInSecondsSource.asObservable();
  textareaClicked = new EventEmitter();

  constructor(private api: ApiService) {}

  updateContent(content: { progressId: string; isVideo: boolean }) {
    this.content = content;
    this.contentSource.next(content);
  }

  getCurrentTimeStamp(currentTimeStamp: number) {
    this.timeStampInSecondsSource.next(currentTimeStamp);
  }

  textAreaClicked() {
    this.textareaClicked.emit();
  }

  getAllVideoBookmarks(videoProgressId: string): Observable<BookmarkNotesListingModel[]> {
    const params = new HttpParams().set('progressId', videoProgressId);

    return this.api.get(this.routeConstants.GET_ALL_VIDEO_BOOKMARKS, params);
  }

  getAllKnowledgeTestBookmarks(videoProgressId: string): Observable<BookmarkNotesListingModel[]> {
    const params = new HttpParams().set('progressId', videoProgressId);

    return this.api.get(this.routeConstants.GET_ALL_KNOWLEDGE_TESTS_BOOKMARKS, params);
  }

  createBookmark(request: BookmarkNoteRequestModel) {
    return this.api.post(this.routeConstants.CREATE_BOOKMARK, request);
  }

  updateBookmark(request: BookmarkNoteRequestModel) {
    return this.api.put(this.routeConstants.UPDATE_BOOKMARK, request);
  }

  removeBookmark(id: string) {
    const params = new HttpParams().set('id', id);

    return this.api.delete(this.routeConstants.REMOVE_BOOKMARK, params);
  }
}
